import React, { useEffect, useState } from "react";
import AgentSidebar from "./AgentSidebar";
import AgentMessageWrapper from "./AgentsMsgs";
import { useGetAgentConversationsQuery } from "../../services/agentConversations";
import { useLocation } from "react-router-dom";
import isEqual from "lodash/isEqual"; // Import a utility for deep comparison

const Agents = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const myParam = params.get("id");

  const [sidebarData, setSidebarData] = useState([]);

  const { data, error, isFetching, refetch } =
    useGetAgentConversationsQuery(myParam);

  const [newChat, setNewChat] = useState();
  const [showWelcome, setShowWelcome] = useState(
    isFetching && data?.conversations && data.conversations?.length === 0
      ? true
      : false
  );

  // useEffect(() => {
  //   if (data && data.conversations)
  //     setShowWelcome(
  //       isFetching && data?.conversations && data.conversations?.length === 0
  //         ? true
  //         : false
  //     );
  // }, [data, isFetching, showWelcome]);

  useEffect(() => {
    if (data && data.conversations) {
      if (
        !sidebarData.conversations ||
        !isEqual(sidebarData.conversations, data.conversations)
      ) {
        setSidebarData(data);
      }
      setShowWelcome(isFetching && data?.conversations?.length === 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <AgentSidebar
        data={sidebarData.conversations}
        error={error}
        isFetching={isFetching}
        newChat={newChat}
        setNewChat={setNewChat}
        setShowWelcome={setShowWelcome}
        myParam={myParam}
      />
      <AgentMessageWrapper
        data={data}
        error={error}
        isFetching={isFetching}
        newChat={newChat}
        setNewChat={setNewChat}
        showWelcome={showWelcome}
        myParam={myParam}
      />
    </>
  );
};

export default Agents;
